<!-- 高清变焦相机Z60S -->
<template>
  <div class="cpt-MMC_Gimbal_Z60S" :style="containerStyle">
    <div class="hd" v-interact>
      <div class="left ml8">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="title">高清变焦相机Z60S</div>
      </div>
      <div @click="close" class="close">关闭</div>
    </div>
    <div class="bd">
      <div class="form-wrap">
        <div class="form-item">
          <div class="label-box">控制选项</div>
          <div class="input-box">
            <el-select
              v-model="control_mode"
              @change="change_control_mode"
              size="mini"
              placeholder="选择模式"
            >
              <el-option label="一键向下" :value="0"></el-option>
              <el-option label="航向回中" :value="1"></el-option>
              <el-option label="俯仰水平" :value="2"></el-option>
              <el-option label="回中" :value="3"></el-option>
            </el-select>
          </div>
        </div>

        <div class="form-item">
          <div class="label-box">点 击</div>
          <div class="input-box">
            <div class="mono">
              <div class="mono-long">
                <div
                  class="mono-left mono_box"
                  :class="{ active: holderModelDomV1 == 0 }"
                  @click="handle_change_click_mode(0)"
                ></div>
                <div
                  class="mono-middle mono_box"
                  :class="{ active: holderModelDomV1 == 1 }"
                  @click="handle_change_click_mode(1)"
                ></div>
                <div
                  class="mono-right mono_box"
                  :class="{ active: holderModelDomV1 == 2 }"
                  @click="handle_change_click_mode(2)"
                ></div>
              </div>
              <div class="text">
                <div class="text-left">移动</div>
                <div class="text-right">聚焦</div>
                <div class="text-right">曝光</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">云台模式</div>
          <div class="input-box">
            <div class="mono">
              <div class="mono-long">
                <div
                  class="mono-left mono_box"
                  :class="{ active: holderModelDomV2 == 0 }"
                  @click="handle_change_ptz_mode(0)"
                ></div>
                <div
                  class="mono-middle mono_box"
                  :class="{ active: holderModelDomV2 == 1 }"
                  @click="handle_change_ptz_mode(1)"
                ></div>
                <div
                  class="mono-right mono_box"
                  :class="{ active: holderModelDomV2 == 2 }"
                  @click="handle_change_ptz_mode(2)"
                ></div>
              </div>
              <div class="text">
                <div class="text-left">锁定</div>
                <div class="text-right">跟随</div>
                <div class="text-right">回中</div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="form-item">
          <div class="label-box">指点移动:</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_zd_mode" v-model="zd_mode">
              <el-radio
                size="mini"
                v-for="item in drd.dj_mode"
                :key="item.id"
                :label="item.id"
              >{{ item.label }}</el-radio>
            </el-radio-group>
          </div>
        </div> -->
        <div class="form-item">
          <div class="label-box">跟踪控制:</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_dj_mode" v-model="dj_mode">
              <el-radio
                size="mini"
                v-for="item in drd.dj_mode"
                :key="item.id"
                :label="item.id"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <div class="item-group jcsb ml100 w100">
          <div @click="handle_take_photo" class="item-box">
            <el-tooltip
              class="item"
              effect="dark"
              content="拍照"
              placement="top"
            >
              <div class="icon-box cp">
                <!-- <span class="iconfont icon-paizhao1"></span> -->
                <img src="~@/assets/images/mount/photo.png" alt />
              </div>
            </el-tooltip>
          </div>
          <div @click="handle_record" class="item-box">
            <el-tooltip
              class="item"
              effect="dark"
              content="录像"
              placement="top"
            >
              <div class="icon-box cp">
                <!-- <span class="iconfont icon-dandianshipin"></span> -->
                <img
                  v-if="!record"
                  src="~@/assets/images/mount/record.png"
                  alt
                />
                <img v-else src="~@/assets/images/mount/stop.png" alt />
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">AI识别:</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_ai_mode" v-model="ai_mode">
              <el-radio
                size="mini"
                v-for="item in drd.dj_mode"
                :key="item.id"
                :label="item.id"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            俯&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;仰
          </div>
          <div class="input-box">
            <el-input
              v-model.number="pitch"
              size="mini"
              max="30"
              min="-90"
              value="0"
              onblur="if(value>30)value=30;if(value<-90)value=-90;if(!value)value=0"
              placeholder="请输入俯仰"
            />
            <el-button size="mini" type="primary" @click="handle_change_pitch">
              <span class="cf">设置</span>
            </el-button>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            航&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;向
          </div>
          <div class="input-box">
            <el-input
              max="145"
              min="-145"
              onblur="if(value>145)value=145;if(value<-145)value=-145;if(!value)value=0"
              v-model.number="yaw"
              size="mini"
              placeholder="请输入航向"
            />
            <el-button @click="handle_change_yaw" size="mini" type="primary">
              <span class="cf">设置</span>
            </el-button>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            变&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;焦
          </div>
          <div class="input-box">
            <div
              class="mr10 cur"
              @mousedown="handle_zoom_reduce()"
              @mouseup="stopChange"
              @mouseout="stopChange"
            >
              <span class="iconfont icon-shanchu3"></span>
            </div>
            <el-slider
              v-model="value"
              :max="50"
              :min="0"
              :show-tooltip="false"
              style="width: 10px"
              @change="stopChange"
              @input="onChangezoom"
            ></el-slider>
            <div
              class="ml10 cur"
              @mousedown="handle_zoom_plus()"
              @mouseup="stopChange"
              @mouseout="stopChange"
            >
              <span class="iconfont icon-tianjia1"></span>
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">光圈调节</div>
          <div class="input-box">
            <el-select
              v-model="aqerturn_mode"
              @change="change_aqerturn_mode"
              size="mini"
              placeholder="选择模式"
            >
              <el-option label="关" :value="0"></el-option>
              <el-option label="1" :value="1"></el-option>
              <el-option label="2" :value="2"></el-option>
              <el-option label="3" :value="3"></el-option>
              <el-option label="4" :value="3"></el-option>
            </el-select>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">色彩增益</div>
          <div class="input-box">
            <el-select
              v-model="colour_mode"
              @change="change_colour_mode"
              size="mini"
              placeholder="选择模式"
            >
              <el-option label="关" :value="0"></el-option>
              <el-option label="1" :value="1"></el-option>
              <el-option label="2" :value="2"></el-option>
              <el-option label="3" :value="3"></el-option>
              <el-option label="4" :value="3"></el-option>
            </el-select>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box w87">可见度增强:</div>
          <div class="input-box">
            <el-radio-group
              @change="handle_change_visibility_mode"
              v-model="visibility"
            >
              <el-radio
                size="mini"
                v-for="item in drd.dj_mode"
                :key="item.id"
                :label="item.id"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">电子增稳</div>
          <div class="input-box">
            <div class="mono">
              <div class="mono-long">
                <div
                  class="mono-left mono_box"
                  :class="{ active: holder_electron_value == 0 }"
                  @click="handle_change_electron_mode(0)"
                ></div>
                <div
                  class="mono-middle mono_box"
                  :class="{ active: holder_electron_value == 1 }"
                  @click="handle_change_electron_mode(1)"
                ></div>
                <div
                  class="mono-right mono_box"
                  :class="{ active: holder_electron_value == 2 }"
                  @click="handle_change_electron_mode(2)"
                ></div>
              </div>
              <div class="text">
                <div class="text-left">关闭</div>
                <div class="text-right">超级</div>
                <div class="text-right">超级+</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">夜间模式</div>
          <div class="input-box">
            <div class="mono">
              <div class="mono-long">
                <div
                  class="mono-left mono_box"
                  :class="{ active: holder_night_value == 0 }"
                  @click="handle_change_night_mode(0)"
                ></div>
                <div
                  class="mono-middle mono_box"
                  :class="{ active: holder_night_value == 1 }"
                  @click="handle_change_night_mode(1)"
                ></div>
                <div
                  class="mono-right mono_box"
                  :class="{ active: holder_night_value == 2 }"
                  @click="handle_change_night_mode(2)"
                ></div>
              </div>
              <div class="text">
                <div class="text-left">自动</div>
                <div class="text-right">黑白</div>
                <div class="text-right">彩色</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">降 噪:</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_jz_mode" v-model="jz_mode">
              <el-radio
                size="mini"
                v-for="item in drd.dj_mode"
                :key="item.id"
                :label="item.id"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">联控模式:</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_lk_mode" v-model="lk_mode">
              <el-radio
                size="mini"
                v-for="item in drd.dj_mode"
                :key="item.id"
                :label="item.id"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">宽 动 态:</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_kdt_mode" v-model="Kdt_mode">
              <el-radio
                size="mini"
                v-for="item in drd.dj_mode"
                :key="item.id"
                :label="item.id"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">电子变倍:</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_dz_mode" v-model="dz_mode">
              <el-radio
                size="mini"
                v-for="item in drd.dj_mode"
                :key="item.id"
                :label="item.id"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">透雾:</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_tw_mode" v-model="tw_mode">
              <el-radio
                size="mini"
                v-for="item in drd.dj_mode"
                :key="item.id"
                :label="item.id"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">白 平 衡</div>
          <div class="input-box">
            <div class="mono">
              <div class="mono-long">
                <div
                  class="mono-left mono_box"
                  :class="{ active: holder_bph_value == 0 }"
                  @click="handle_change_bph_mode(0)"
                ></div>
                <div
                  class="mono-middle mono_box"
                  :class="{ active: holder_bph_value == 1 }"
                  @click="handle_change_bph_mode(1)"
                ></div>
                <div
                  class="mono-right mono_box"
                  :class="{ active: holder_bph_value == 2 }"
                  @click="handle_change_bph_mode(2)"
                ></div>
              </div>
              <div class="text">
                <div class="text-left">自动</div>
                <div class="text-right">室内</div>
                <div class="text-right">室外</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rocker-wrap">
        <div class="rocker" ref="rocker">
          <div
            class="shangUp"
            @mousedown="fangxiang('up')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          ></div>
          <div
            class="xaiUp"
            @mousedown="fangxiang('down')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          ></div>
          <div
            class="zuoUp"
            @mousedown="fangxiang('right')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          ></div>
          <div
            class="youUp"
            @mousedown="fangxiang('left')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import buffer from "@turf/buffer";
import { MMC_Gimbal_Z60S } from "../utils";
export default {
  props: {
    containerStyle: {
      type: Object,
      default: () => ({}),
    },
    device: {
      type: Object,
      default: () => ({}),
    },
    selected_mount: {
      type: Object,
      default: () => ({}),
    },
    moveType: {
      type: String,
      default: () => "",
    },
    keyFlag: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      // 选择模式
      control_mode: 3,

      // 点击
      holderModelDomV1: 1,
      //云台模式
      holderModelDomV2: 1,
      // 跟踪控制
      dj_mode: 0,
      // 俯仰
      pitch: 0,
      // 航向
      yaw: 0,
      // 光圈调节
      aqerturn_mode: 0,
      // 色彩增益
      colour_mode: 0,
      // 可见度增强
      visibility: 1,
      // 电子增稳
      holder_electron_value: 1,
      // 夜间模式
      holder_night_value: 0,
      // 降噪
      jz_mode: 1,
      // 联控模式
      lk_mode: 0,
      zd_mode: 0,
      // 宽动态
      Kdt_mode: 0,
      // 电子变倍
      dz_mode: 0,
      //透雾
      tw_mode: 0,
      // 白平衡
      holder_bph_value: 0,
      // AI识别
      ai_mode: 1,
      timer: null,
      value: 25,
      value2: 25,
      ptz_mode: 1,
      record: false,
      radio: 1,

      drd: {
        dj_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "打开" },
        ],
        ptz_mode: [
          { id: 0, label: "锁定" },
          { id: 1, label: "跟随" },
          { id: 2, label: "回中" },
        ],
      },
    };
  },
  computed: {
    stream() {
      return this.$store.state.fckernel.stream;
    },
  },
  methods: {
    // 点击
    handle_change_click_mode(value) {
      this.holderModelDomV1 = value;
      let list = {
        0: [165, 79, 11, 85, 170, 220, 6, 30, 0, 1, 0, 25, 118],
        1: [165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 5, 8, 2, 255, 134, 186],
        2: [165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 89, 2, 255, 214, 84],
      };
      let buffer = list[value];
      this.commit_directive(buffer);
    },
    // 云台模式
    handle_change_ptz_mode(value) {
      this.holderModelDomV2 = value;
      let bufferList = {
        0: [
          165, 79, 17, 85, 170, 220, 12, 26, 10, 0, 0, 0, 0, 0, 0, 0, 0, 28,
          197,
        ],
        1: [
          165, 79, 17, 85, 170, 220, 12, 26, 3, 0, 0, 0, 0, 0, 0, 0, 0, 21, 53,
        ],
        2: [
          165, 79, 17, 85, 170, 220, 12, 26, 4, 0, 0, 0, 0, 0, 0, 0, 0, 18, 101,
        ],
      };
      let buffer = bufferList[value];
      this.commit_directive(buffer);
    },
    // 控制选项
    change_control_mode() {
      let { control_mode } = this;
      let control_modeList = {
        0: [
          165, 79, 17, 85, 170, 220, 12, 26, 18, 0, 0, 0, 0, 0, 0, 0, 0, 4, 105,
        ],
        1: [
          165, 79, 17, 85, 170, 220, 12, 26, 22, 0, 0, 0, 0, 0, 0, 0, 0, 0, 180,
        ],
        2: [
          165, 79, 17, 85, 170, 220, 12, 26, 23, 0, 0, 0, 0, 0, 0, 0, 0, 1, 207,
        ],
        3: [
          165, 79, 17, 85, 170, 220, 12, 26, 4, 0, 0, 0, 0, 0, 0, 0, 0, 18, 101,
        ],
      };
      let buffer = control_modeList[control_mode];
      this.commit_directive(buffer);
    },
    //跟踪控制
    handle_change_dj_mode(id) {
      let buffer = null;
      // 停止
      if (id == 0) {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 52, 2, 255, 187, 186,
        ];
        let buffer1 = [165, 79, 11, 85, 170, 220, 6, 30, 0, 1, 0, 25, 118];
        this.commit_directive(buffer1);
      } else {
        // 控制
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 52, 3, 255, 186, 205,
        ];
        this.$store.commit("device/SET_MOVE_DATA", {
          ...this.device,
          mountStatus: id,
          mountId: this.selected_mount.mountId,
          moveType: this.moveType,
          name: this.selected_mount.name,
        });
      }
      this.commit_directive(buffer);
    },
    // 指点移动
    handle_change_zd_mode(id) {
      this.$store.commit("device/SET_MOVE_DATA", {
        ...this.device,
        mountStatus: id,
        mountId: this.selected_mount.mountId,
        moveType: this.moveType,
        name: this.selected_mount.name,
      });
    },
    handle_change_pitch() {
      if (this.pitch > 200) {
        this.pitch = 200;
      }
      if (this.pitch) {
        let buffer = MMC_Gimbal_Z60S.change_pitch_angle(this.pitch);
        this.commit_directive(buffer);
      }
    },
    handle_change_yaw() {
      let buffer = MMC_Gimbal_Z60S.change_yaw_angle(this.yaw);
      this.commit_directive(buffer);
    },
    //变焦 -
    handle_zoom_reduce() {
      let arr = [165, 79, 10, 85, 170, 220, 5, 28, 2, 56, 35, 6];
      this.commit_directive(arr);
    },
    //变焦 +
    handle_zoom_plus() {
      let arr = [165, 79, 10, 85, 170, 220, 5, 28, 2, 120, 99, 178];
      this.commit_directive(arr);
    },
    stopChange() {
      this.value = 25;
      this.value2 = 25;
      let buffer = [165, 79, 10, 85, 170, 220, 5, 28, 0, 120, 97, 92];
      this.commit_directive(buffer);
    },
    handle_zoom(mode) {
      let buffer = [165, 79, 10, 85, 170, 220, 5, 28, 0, 120, 97, 92];
      this.commit_directive(buffer);
    },
    // 光圈调节
    change_aqerturn_mode() {
      let { aqerturn_mode } = this;
      if (aqerturn_mode != 0) {
        let buffer = [
          165, 79, 16, 85, 170, 220, 11, 255, 129, 1, 4, 36, 95, 0, 2, 255, 246,
          82,
        ];
        this.commit_directive(buffer);
      }
      const aqerturn_mode_list = {
        0: [
          165, 79, 16, 85, 170, 220, 11, 255, 129, 1, 4, 36, 95, 0, 0, 255, 244,
          188,
        ],
        1: [
          165, 79, 18, 85, 170, 220, 13, 255, 129, 1, 4, 31, 66, 0, 0, 2, 0,
          255, 214, 106,
        ],
        2: [
          165, 79, 18, 85, 170, 220, 13, 255, 129, 1, 4, 31, 66, 0, 0, 4, 0,
          255, 208, 197,
        ],
        3: [
          165, 79, 18, 85, 170, 220, 13, 255, 129, 1, 4, 31, 66, 0, 0, 6, 0,
          255, 210, 160,
        ],
        4: [
          165, 79, 18, 85, 170, 220, 13, 255, 129, 1, 4, 31, 66, 0, 0, 8, 0,
          255, 220, 170,
        ],
      };
      this.commit_directive(aqerturn_mode_list[aqerturn_mode]);
    },
    // 色彩增益
    change_colour_mode() {
      let { colour_mode } = this;
      if (colour_mode != 0) {
        let buffer = [
          165, 79, 16, 85, 170, 220, 11, 255, 129, 1, 4, 36, 95, 0, 6, 255, 242,
          191,
        ];
        this.commit_directive(buffer);
      }
      const colour_mode_list = {
        0: [
          165, 79, 16, 85, 170, 220, 11, 255, 129, 1, 4, 36, 95, 0, 2, 255, 246,
          82,
        ],
        1: [
          165, 79, 18, 85, 170, 220, 13, 255, 129, 1, 4, 31, 73, 0, 0, 2, 0,
          255, 221, 85,
        ],
        2: [
          165, 79, 18, 85, 170, 220, 13, 255, 129, 1, 4, 31, 73, 0, 0, 4, 0,
          255, 219, 250,
        ],
        3: [
          165, 79, 18, 85, 170, 220, 13, 255, 129, 1, 4, 31, 73, 0, 0, 6, 0,
          255, 217, 159,
        ],
        4: [
          165, 79, 18, 85, 170, 220, 13, 255, 129, 1, 4, 31, 73, 0, 0, 8, 0,
          255, 215, 149,
        ],
      };
      this.commit_directive(colour_mode_list[colour_mode]);
    },
    // 可见度增强
    handle_change_visibility_mode(num) {
      let buffer = null;
      if (num == 0) {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 61, 3, 255, 179, 194,
        ];
      } else {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 61, 6, 255, 182, 88,
        ];
      }
      this.commit_directive(buffer);
    },
    // 电子增稳
    handle_change_electron_mode(num) {
      this.holder_electron_value = num;
      let buffer = null;
      if (num == 0) {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 52, 3, 255, 186, 205,
        ];
      } else if (num == 1) {
        buffer = [
          165, 79, 15, 85, 170, 220, 10, 255, 129, 1, 126, 4, 52, 2, 255, 198,
          20,
        ];
      } else {
        buffer = [
          165, 79, 15, 85, 170, 220, 10, 255, 129, 1, 126, 4, 52, 3, 255, 199,
          99,
        ];
      }
      this.commit_directive(buffer);
    },
    // 夜间模式
    handle_change_night_mode(num) {
      this.holder_night_value = num;
      let buffer = null;
      if (num == 0) {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 81, 3, 255, 223, 134,
        ];
      } else if (num == 1) {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 1, 2, 255, 142, 87,
        ];
      } else {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 1, 3, 255, 143, 32,
        ];
      }
      this.commit_directive(buffer);
    },
    // 降噪
    handle_change_jz_mode(num) {
      let buffer = null;
      if (num == 0) {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 83, 0, 255, 222, 122,
        ];
      } else {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 83, 5, 255, 219, 224,
        ];
      }
      this.commit_directive(buffer);
    },
    handle_change_lk_mode(num) {
      let buffer = null;
      if (num == 0) {
        buffer = [166, 1, 3, 0, 107];
      } else {
        buffer = [166, 1, 3, 1, 90];
      }
      this.commit_directive(buffer);
    },
    // 宽动态
    handle_change_kdt_mode(num) {
      let buffer = null;
      if (num == 0) {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 61, 3, 255, 179, 194,
        ];
      } else {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 61, 2, 255, 178, 181,
        ];
      }
      this.commit_directive(buffer);
    },
    // 电子变倍
    handle_change_dz_mode(num) {
      let buffer = null;
      if (num == 0) {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 6, 3, 255, 136, 37,
        ];
      } else {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 6, 2, 255, 137, 82,
        ];
      }
      this.commit_directive(buffer);
    },
    // 透雾
    handle_change_tw_mode(num) {
      let buffer = null;
      if (num == 0) {
        buffer = [
          165, 79, 15, 85, 170, 220, 10, 255, 129, 1, 4, 55, 3, 0, 255, 186,
          187,
        ];
      } else {
        buffer = [
          165, 79, 15, 85, 170, 220, 10, 255, 129, 1, 4, 55, 2, 3, 255, 184,
          136,
        ];
      }
      this.commit_directive(buffer);
    },
    // 白平衡
    handle_change_bph_mode(num) {
      this.holder_bph_value = num;
      let buffer = null;
      if (num == 0) {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 53, 0, 255, 184, 254,
        ];
      } else if (num == 1) {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 53, 1, 255, 185, 137,
        ];
      } else {
        buffer = [
          165, 79, 14, 85, 170, 220, 9, 255, 129, 1, 4, 53, 2, 255, 186, 16,
        ];
      }
      this.commit_directive(buffer);
    },
    // AI识别
    handle_change_ai_mode(num) {
      let buffer = null;
      if (num == 0) {
        buffer = [165, 79, 11, 85, 170, 220, 6, 30, 0, 5, 0, 29, 155];
      } else {
        buffer = [165, 79, 11, 85, 170, 220, 6, 30, 0, 5, 1, 28, 94];
      }
      this.commit_directive(buffer);
    },
    close() {
      if (this.record) {
        this.$message.info("请结束录像后再关闭！");
      } else {
        this.$emit("close");
      }
    },
    handle_take_photo() {
      // let { position } = this;
      // let buffer = MMC_Gimbal_Z30Pro.take_photo(position || {});
      if (!this.keyFlag) return this.$message.error("拍照失败，板载通信异常！");
      let streamData = {
        data: {
          data: {
            videoID: 1,
          },
        },
        type: 528,
      };
      if (this.stream == "QingLiu") {
        streamData.data.messageID = 1016;
      } else {
        streamData.data.messageID = 1007;
      }
      this.$emit("take_photo", streamData);
    },
    handle_record() {
      if (!this.keyFlag) return this.$message.error("录像失败，板载通信异常！");
      this.record = !this.record;
      let streamData = {
        data: {
          data: {
            videoID: 1,
          },
        },
        type: 528,
      };
      if (this.stream == "QingLiu") {
        streamData.data.messageID = 1017;
        streamData.data.data.status = this.record;
      } else {
        streamData.data.messageID = 1006;
        streamData.data.data.recordControl = this.record;
      }
      this.$emit("record", streamData);

      // let buffer = MMC_Gimbal_Z30Pro.record(this.record);
      // this.commit_directive(buffer);
    },

    onChangezoom(e) {
      console.log(e, "e");
      if (e < 25) {
        let arr = [165, 4, 3, 0, 4];
        this.commit_directive(arr);
        // this.handle_zoom(0);
      }
      if (e > 25) {
        let arr = [165, 4, 3, 2, 102];
        this.commit_directive(arr);
      }
      if (e == 25) {
        this.handle_zoom(1);
      }
      this.value2 = e;
    },

    commit_directive(buffer) {
      console.log(buffer, "buffer");
      this.$emit("directive", buffer);
    },
    fangxiang(type) {
      let buffer = null;
      switch (type) {
        case "up":
          buffer = [
            165, 79, 17, 85, 170, 220, 12, 26, 13, 0, 0, 5, 220, 0, 0, 4, 176,
            118, 108,
          ];
          this.commit_directive(buffer);

          break;
        case "left":
          buffer = [
            165, 79, 17, 85, 170, 220, 12, 26, 13, 0, 0, 7, 8, 0, 0, 5, 220,
            205, 15,
          ];
          this.commit_directive(buffer);
          break;
        case "right":
          buffer = [
            165, 79, 17, 85, 170, 220, 12, 26, 13, 0, 0, 4, 176, 0, 0, 5, 220,
            118, 216,
          ];
          this.commit_directive(buffer);

          break;
        case "down":
          buffer = [
            165, 79, 17, 85, 170, 220, 12, 26, 13, 0, 0, 5, 220, 0, 0, 7, 8,
            205, 85,
          ];
          this.commit_directive(buffer);
      }
    },
    stopfxFn() {
      let buffer = [
        165, 79, 17, 85, 170, 220, 12, 26, 13, 0, 0, 5, 220, 0, 0, 5, 220, 27,
        60,
      ];
      this.commit_directive(buffer);
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-MMC_Gimbal_Z60S {
  width: 500px;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;
  background-size: 100% 100%;
  box-sizing: border-box;
  // padding: 10px 20px;
  position: absolute;
  right: 0px;

  .hd {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;

    .left {
      display: flex;
      align-items: center;
      .title {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }

  .bd {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
    height: 353px;
    .form-wrap {
      flex-shrink: 0;

      .form-item {
        display: flex;
        align-items: center;
        height: 40px;

        .label-box {
          color: #cbd0eb;
          width: 75px;
        }

        .input-box {
          display: flex;
          align-items: center;
          .mono {
            display: flex;
            flex-direction: column;
            .mono-long {
              display: flex;
              justify-content: space-between;
              width: 168px;
              height: 17px;
              background: url("~@/assets/images/mount_long.png") no-repeat;
              background-size: 100%;
              margin-bottom: 5px;
              .mono_box {
                width: 28px;
                height: 13px;
                border-radius: 3px;
                margin-top: 2px;
                cursor: pointer;
              }
              .active {
                background: url("~@/assets/images/mount/mount_control.png")
                  no-repeat !important;
                background-size: 100% 100%;
              }
            }
            .text {
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #ffffff;
              display: flex;
              justify-content: space-between;
              font-size: 14px;
            }
          }
        }
      }
    }

    .rocker-wrap {
      flex: 1;

      .title-box {
        .title {
          color: #cbd0eb;
        }
      }

      .rocker {
        margin: 10px auto 0;
        width: 150px;
        height: 150px;
        position: relative;
        margin: 0 auto;
        background: center url("~@/assets/images/accident/caozuo.png") no-repeat;
        background-size: 100% 100%;

        .shangUp {
          position: absolute;
          left: 37%;
          top: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .xaiUp {
          position: absolute;
          left: 37%;
          bottom: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .zuoUp {
          position: absolute;
          left: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .youUp {
          position: absolute;
          right: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
      }
    }
  }
}

::v-deep {
  .el-select {
    width: 165px;
    margin-right: 5px;

    .el-input {
      .el-input__inner {
        background: #000000;
        border: 1px solid #004fff;
        color: #dce9ff;
      }
    }
  }

  .el-input {
    width: 165px;
    margin-right: 5px;

    .el-input__inner {
      background: #000000;
      border: 1px solid #004fff;
      color: #dce9ff;
    }
  }

  .el-radio-group {
    .el-radio {
      .el-radio__input {
        &.is-checked {
          .el-radio__inner {
            border-color: #004fff;
            background: #004fff;
          }
        }
      }

      .el-radio__label {
        color: #cbd0eb;
      }
    }
  }

  .el-button {
    background: #004fff;
    color: #000;
    border: none;
  }
}

/deep/.el-slider__bar {
  background: #fff;
}

.speedC {
  width: 203px;
  height: 24px;
  display: flex;
  background: rgba(13, 34, 79, 0.65);
  border: 1px solid #004fff;
  border-radius: 12px;

  .speed {
    width: 41px;
    // background: #FFFFFF;
    color: #ffffff;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
  }
}

// 变焦功能
.iconfont {
  color: #004fff;
}
// 变焦功能
/deep/ .el-slider__runway {
  visibility: hidden;
}
.cur {
  cursor: pointer;
}
</style>
